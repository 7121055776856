import React, { memo } from 'react';

import { ClassName, UUID } from '../../../../../types';

import { IndexTableCell } from '../IndexTableCell';

import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';

interface IndexTableCellLinkItemWithName {
  uuid: UUID;
  name: string;
}

interface IndexTableCellLinkItemWithLocalizedName {
  uuid: UUID;
  localizedName: string;
}

interface IndexTableCellLinkProps {
  className?: ClassName;
  scope: string;
  item:
    | IndexTableCellLinkItemWithName
    | IndexTableCellLinkItemWithLocalizedName
    | null;
}

function IndexTableCellLink({
  scope,
  item,
  className
}: IndexTableCellLinkProps) {
  return (
    <IndexTableCell>
      {item ? (
        <div className="flex flex-wrap">
          <NextLinkHelper
            key={item.uuid}
            href={`/${scope}/${item.uuid}`}
            className={
              className ||
              'inline-flex items-center px-2 py-0.5 m-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 dark:text-gray-100 dark:bg-gray-700 dark:bg-opacity-50'
            }
          >
            {'localizedName' in item ? item.localizedName : null}
            {'name' in item ? item.name : null}
          </NextLinkHelper>
        </div>
      ) : null}
    </IndexTableCell>
  );
}

export default memo<IndexTableCellLinkProps>(IndexTableCellLink);
