import { FiltersPopoverTypesEnum, IsLoading } from '../../../../../types';

import { SearchFilterNanoID } from '../../../../searchFilters/searchFiltersTypes';

import {
  FetchCategoriesClearCategoriesFilters,
  FetchCategoriesFilterCategories,
  FetchCategoriesFilters
} from '../../../categoriesTypes';

import { useIndexPageFiltersPopover } from '../../../../common/hooks/useIndexPageFiltersPopover';

import { FilterCategoriesForm } from '../../forms/FilterCategoriesForm';

const filtersCategoriesPopoverTargetId = 'categories-filters-popover-button';

interface FilterCategoriesPopoverProps {
  categoriesFilters: FetchCategoriesFilters;
  clearCategoriesFilters: FetchCategoriesClearCategoriesFilters;
  filterCategories: FetchCategoriesFilterCategories;
  isLoading?: IsLoading;
  onCreateSearchFilter?: (searchFilterNanoId: SearchFilterNanoID) => void;
  onPopoverClose?: () => void;
  onResetFilters?: () => void;
}

function FilterCategoriesPopover({
  categoriesFilters,
  clearCategoriesFilters,
  filterCategories,
  isLoading,
  onCreateSearchFilter,
  onPopoverClose,
  onResetFilters
}: FilterCategoriesPopoverProps) {
  const {
    filtersSaving,
    handleFiltersChange,
    handleFiltersClear,
    handleFiltersSave,
    popoverDivRef
  } = useIndexPageFiltersPopover<FetchCategoriesFilters>({
    popoverType: FiltersPopoverTypesEnum.ACTIVE_FILTERS,
    onFiltersChange: filterCategories,
    onCreateSearchFilter,
    onClosePopover: onPopoverClose,
    onFiltersClear: clearCategoriesFilters,
    targetId: filtersCategoriesPopoverTargetId,
    onResetFilters
  });

  return (
    <div
      className="absolute flex left-0 max-h-screen overflow-hidden pb-32 md:pb-16 pointer-events-none pt-14 px-4 top-0 w-full"
      ref={popoverDivRef}
    >
      <FilterCategoriesForm
        afterSubmit={onPopoverClose}
        categoriesFilters={categoriesFilters}
        filterCategories={handleFiltersChange}
        isLoading={isLoading || filtersSaving}
        onClear={handleFiltersClear}
        onSaveFilters={handleFiltersSave}
      />
    </div>
  );
}

export default FilterCategoriesPopover;
