import { GraphQLClient } from 'graphql-request';

import { GRAPHQL_API_URI } from '../../../../../config';

import {
  FetchCategoriesFilters,
  FetchCategoriesSort,
  FetchCategoriesPage,
  FetchCategoriesLimit
} from '../../../categoriesTypes';

interface FetchCategoriesProps {
  query: string;
  filters: FetchCategoriesFilters;
  sort: FetchCategoriesSort;
  page: FetchCategoriesPage;
  limit: FetchCategoriesLimit;
}

const graphQLClient = new GraphQLClient(GRAPHQL_API_URI, {
  credentials: 'include',
  mode: 'cors'
  // fetch
});

export function fetchCategories({
  query,
  filters,
  sort,
  page,
  limit
}: FetchCategoriesProps) {
  return graphQLClient.request(query, {
    filters,
    sort,
    limit,
    offset: (page - 1) * limit
  });
}
