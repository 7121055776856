import React, { useCallback, useEffect, useState } from 'react';

import { SearchFilterNanoID } from '../../../searchFilters/searchFiltersTypes';

import {
  CategoryUUID,
  FetchCategoriesCacheKey,
  FetchCategoriesFilters,
  FetchCategoriesLimit,
  FetchCategoriesPage
} from '../../categoriesTypes';

import { useCategories } from '../../hooks/useCategories';
import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';
import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { CategoriesIndexPageHeader } from '../../components/headers/CategoriesIndexPageHeader';
import { CategoriesIndexPageSubHeader } from '../../components/headers/CategoriesIndexPageSubHeader';
import { CategoriesIndexTable } from '../../components/tables/CategoriesIndexTable';
import { IndexLayout } from '../../../common/layouts/IndexLayout';
import { SearchFiltersScopes } from '../../../searchFilters/searchFiltersConstants';
import { IndexPagePopover } from '../../../common/components/popovers/IndexPagePopover';
import { FilterCategoriesPopover } from '../../components/popovers/FilterCategoriesPopover';

import { GET_CATEGORIES_QUERY } from './CategoriesIndexPage.query';
import { INITIAL_ROLES_FILTERS } from '../../../roles/rolesConstants';
import { CategoriesPermissions } from '../../categoriesConstants';
import { categoriesKeys } from '../../../../locales/keys';

const searchFiltersCategoriesPopoverTargetId = 'categories-text-filter-input';

interface CategoriesIndexPageProps {
  cacheKey: FetchCategoriesCacheKey;
  initialPage: FetchCategoriesPage;
  initialLimit: FetchCategoriesLimit;
  initialFilters?: FetchCategoriesFilters;
  searchFilterNanoId?: SearchFilterNanoID;
  onCreateSearchFilter?: (searchFilterNanoId: SearchFilterNanoID) => void;
  onResetFilters?: () => void;
}

function CategoriesIndexPage({
  cacheKey,
  initialPage,
  initialLimit,
  initialFilters = INITIAL_ROLES_FILTERS,
  searchFilterNanoId,
  onCreateSearchFilter,
  onResetFilters
}: CategoriesIndexPageProps) {
  const {
    categories,
    categoriesError,
    categoriesTotalCount,
    categoriesFetched,
    categoriesLoading,
    categoriesFilters,
    categoriesSort,
    categoriesPage,
    categoriesLimit,
    categoriesIsPlaceholderData,
    filterCategories,
    clearCategoriesFilters,
    sortCategories,
    paginateCategories,
    prefetchCategories
  } = useCategories({
    cacheKey,
    query: GET_CATEGORIES_QUERY,
    initialPage,
    initialLimit,
    initialFilters
  });

  const prevFilters = usePreviousValue(initialFilters);
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (prevFilters !== initialFilters) {
      filterCategories(initialFilters);
    }
  }, [prevFilters, filterCategories, initialFilters]);

  const [editableCategoryUuid, setEditableCategoryUuid] =
    useState<CategoryUUID | null>(null);

  const handleCategoryEdit = useCallback(
    (categoryUUid: CategoryUUID) => {
      setEditableCategoryUuid((prevState) => {
        if (prevState === categoryUUid) {
          return null;
        }

        return categoryUUid;
      });
    },
    [setEditableCategoryUuid]
  );

  return (
    <IndexLayout
      action={CategoriesPermissions.READ_CATEGORIES_INDEX_PAGE}
      i18nTitle={categoriesKeys.plural}
      header={
        <CategoriesIndexPageHeader
          categoriesFilters={categoriesFilters}
          filterCategories={filterCategories}
        />
      }
      popover={
        <IndexPagePopover
          searchFilterNanoId={searchFilterNanoId}
          filtersPopover={
            <FilterCategoriesPopover
              categoriesFilters={categoriesFilters}
              clearCategoriesFilters={clearCategoriesFilters}
              filterCategories={filterCategories}
              isLoading={categoriesLoading}
              onCreateSearchFilter={onCreateSearchFilter}
              onResetFilters={onResetFilters}
            />
          }
          onDeleteActiveFilter={onResetFilters}
          popoverTargetId={searchFiltersCategoriesPopoverTargetId}
          scope={SearchFiltersScopes.CATEGORIES_INDEX}
          withSearchFiltersPopover={currentUser.hasPermissions(
            CategoriesPermissions.READ_CATEGORIES_SEARCH_FILTERS_POPOVER
          )}
        />
      }
    >
      <CategoriesIndexPageSubHeader
        categoriesFilters={categoriesFilters}
        filterCategories={filterCategories}
      />
      <CategoriesIndexTable
        categories={categories}
        categoriesError={categoriesError}
        categoriesFetched={categoriesFetched}
        categoriesIsPlaceholderData={categoriesIsPlaceholderData}
        categoriesLimit={categoriesLimit}
        categoriesPage={categoriesPage}
        categoriesSort={categoriesSort}
        categoriesTotalCount={categoriesTotalCount}
        onCategoryEdit={handleCategoryEdit}
        paginateCategories={paginateCategories}
        prefetchCategories={prefetchCategories}
        sortCategories={sortCategories}
      />
    </IndexLayout>
  );
}

export default CategoriesIndexPage;
