import React from 'react';

import { IndexTableCell } from '../IndexTableCell';

import {
  UserAvatarLinkUser,
  UserAvatarLink
} from '../../../helpers/UserAvatarLink';

interface IndexTableCellUserAvatarProps {
  user: UserAvatarLinkUser;
}

function IndexTableCellUserAvatar({ user }: IndexTableCellUserAvatarProps) {
  return (
    <IndexTableCell>
      <UserAvatarLink avatarType="main" user={user} />
    </IndexTableCell>
  );
}

export default IndexTableCellUserAvatar;
